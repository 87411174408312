import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"

// ここにリンクをつけておこう
const moreLinks = [
  { text: "kowori.net", url: "https://kowori.net" },
  { text: "Twitter", url: "https://twitter.com/kowori_fallout" },
  { text: "BlueSky", url: "https://bsky.app/profile/tadakori.bsky.social" },
  { text: "YouTube", url: "https://www.youtube.com/channel/UC2-6vCCrGTqCDE6tTQ9TtKw" },
]

const utmParameters = '';

const availableMachine = () => (
  <Layout>
    <div className={styles.textCenter}>
      <StaticImage
        src="../images/robot_a.png"
        loading="eager"
        width={64}
        quality={95}
        formats={["auto", "webp", "avif"]}
        alt=""
        style={{ marginBottom: `var(--space-3)` }}
      />
      <h1>
        <a href="/">kowori.net</a>
      </h1>
      <p className={styles.intro}>
       <h1>available Machine</h1>
      </p>
    </div>

    <div>
      <ul>
        <li>Nintendo
          <ul>
            <li>Switch Lite</li>
            <li>Wii</li>
            <li>Wii U</li>
            <li>GameCube</li>
             <ul><li>GBA アダプタあり.</li></ul>
            <li>3DSLL</li>
          </ul>
        </li>

        <li>Microsoft
          <ul><li>XBOX Series X</li></ul>
        </li>
        <li>SONY
          <ul>
            <li>PlayStation 3
              <ul><li>初期型 PS1,PS2 ソフトも動作可能</li></ul>
            </li>
            <li>PlayStation 4</li>
              <ul><li>PSVR</li></ul>
            <li>PlayStation 5</li>
          </ul>
        </li>
        <li>SEGA
          <ul>
            <li>DreamCast</li>
          </ul>
        </li>

        Retrofreak . 
      </ul>
    </div>

      <div>
      ...and unavailableMachine
        <ul>
          <li>COLOR TV-GAME 15 ..hehehe</li>
          <li>FC/DISCSYSTEM/SFC/GB/GBA/DS</li>
          <li>PCE/PCE-SG</li>
          <li>SAURN</li>
          <li>PS1/2/PSP</li>
        </ul>
      </div>

    <hr />
    <div className={styles.textCenter}>
    {moreLinks.map((link, i) => (
      <React.Fragment key={link.url}>
        <a href={`${link.url}${utmParameters}`}>{link.text}</a>
        {i !== moreLinks.length - 1 && <> · </>}
      </React.Fragment>
    ))}
    </div>
  </Layout>
  )




export const Head = () => <Seo title="Available machine" />

export default availableMachine
